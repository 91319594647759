import { render, staticRenderFns } from "./DlgAlert.vue?vue&type=template&id=540751a4&scoped=true&"
import script from "./DlgAlert.vue?vue&type=script&lang=js&"
export * from "./DlgAlert.vue?vue&type=script&lang=js&"
import style0 from "./DlgAlert.vue?vue&type=style&index=0&id=540751a4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "540751a4",
  null
  
)

export default component.exports